@import "./variables";

[class*="hover:bg-secondary"]:hover {
  background-color: $secondary;
}

[class*="hover:bg-dark"]:hover {
  background-color: $dark;
}

[class*="hover:bg-light"]:hover {
  background-color: $light;
}

[class*="hover:bg-danger"]:hover {
  background-color: $danger;
}

[class*="hover:bg-warning"]:hover {
  background-color: $warning;
}

[class*="hover:bg-success"]:hover {
  background-color: $success;
}

[class*="hover:bg-primary"]:hover {
  background-color: $primary;
}

[class*="hover:bg-pink"]:hover {
  background-color: $pink;
}

[class*="hover:bg-purple"]:hover {
  background-color: $purple;
}

[class*="hover:bg-teal"]:hover {
  background-color: $teal;
}

[class*="hover:bg-indigo"]:hover {
  background-color: $indigo;
}

[class*="hover:bg-cyan"]:hover {
  background-color: $cyan;
}
