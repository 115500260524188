@import "~react-calendar/dist/Calendar.css";
@import "~react-bootstrap-typeahead/css/Typeahead.css";
@import "~react-phone-number-input/style.css";
@import "./styles/react-calendar.scss";
@import "./styles/bootstrap.scss";
@import "./styles/callout.scss";
@import "./styles/tables.scss";
@import "./styles/text.scss";
@import "./styles/bg.scss";
@import "./styles/forms.scss";
@import "./styles/lists.scss";
@import "./styles/status.scss";
@import "./styles/buttons.scss";
@import "./styles/hover.scss";
@import "./styles/cards.scss";

// https://allthingssmitty.com/2020/05/11/css-fix-for-100vh-in-mobile-webkit/
body {
  min-height: 100vh;
  /* mobile viewport bug fix */
  min-height: -webkit-fill-available;
  background-color: $gray-100;
}

// .modal-backdrop {
//   z-index: 3001;
// }

@media
(max-width: 600px),
(-webkit-min-device-pixel-ratio: 2) {
*::-webkit-scrollbar {
    display: none;
  }

  * {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

.loading {
  z-index: 10000;
  border-top: 5px red var(--bs-primary);
  width:100%;
  height: 5px;
  left: 0;
  position: absolute;
  top:0;
  background:#04acec;
  animation: loading 3s linear infinite
}

.loading-bottom {
  top: auto;
  bottom: 0
}

.loading-sm {
  border-top-height: 2px;
  height: 2px;
}

.loading-xs {
  border-top-height: 1px;
  height: 1px;
}

@keyframes loading {
  from { left: 0%; width: 0; }
  to { left: 0; width: 100%; }
}

.rbt-menu > .dropdown-item {
  white-space: normal;
}

vw-100 {
  width: 100vw!important;
}

w-lg-auto {
  width: auto !important;
}

@media print {
  .shadow,
  .shadow-sm {
    box-shadow: none !important;
    border-bottom: 1px solid $border-color;
  }
  *[role="button"],
  *[aria-hidden="true"] {
    display: none !important;
  }
  .sticky-top,
  .sticky-bottom,
  .sticky-top-1 {
    top: auto !important;
  }
  *::-webkit-input-placeholder {
    opacity: 0 !important;
  }
  *:-moz-placeholder {
    opacity: 0 !important;
  }
  *::-moz-placeholder {
    opacity: 0 !important;
  }
  *:-ms-input-placeholder {
    opacity: 0 !important;
  }
  #print:not(:empty) + #root {
    display: none;
  }
}

@page {
  margin: 5mm;
}

html {
  height: -webkit-fill-available;
}

code {
  white-space: pre;
}

body.inputtype-date {
  .no-inputtype-date {
    display: none !important;
  }
}

body.inputtype-time {
  .no-inputtype-time {
    display: none !important;
  }
}

hr {
  opacity: 0.15;
}

a {
  text-decoration: none;
}

#root {
  .__react_component_tooltip {
    z-index: 2000;
    @media print {
      display: none !important;
    }
  }
}

.sr-only {
  // v5.01 backwards compatibilty
  @include visually-hidden();
}

.overflow-x {
  overflow-x: auto;
}

[class*="link"] {
  cursor: pointer;
}

.btn-link {
  text-decoration: none;
}

.scroll-smooth {
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
}

// https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
.vh-100 {
  height: calc(var(--vh, 1vh) * 100) !important;
}

.vh-100-1 {
  height: calc((var(--vh, 1vh) * 100) - 66px);
}

.mvh-100 {
  min-height: calc(var(--vh, 1vh) * 100) !important;
}

.mvh-100-1 {
  min-height: calc((var(--vh, 1vh) * 100) - 66px);
}

.border-transparent {
  border-color: transparent;
}

.sticky-top {
  position: sticky;
  position: -webkit-sticky; /* Safari */
  top: 0;
  z-index: 999;
}

.sticky-bottom {
  position: sticky;
  position: -webkit-sticky; /* Safari */
  bottom: 0;
}

.sticky-top-1 {
  z-index: 999;
  position: sticky;
  position: -webkit-sticky;
  top: 66px;
}

.rotate-90 {
  transform: rotate(90deg);
}

.zoom-150 {
  zoom: 1.5;
}

.zoom-100 {
  zoom: 1;
}

.zoom-95 {
  zoom: 0.95;
}

.zoom-90 {
  zoom: 0.9;
}

.zoom-85 {
  zoom: 0.85;
}

.zoom-80 {
  zoom: 0.8;
}

.zoom-75 {
  zoom: 0.75;
}

.zoom-70 {
  zoom: 0.7;
}

.zoom-65 {
  zoom: 0.65;
}

.zoom-60 {
  zoom: 0.6;
}

.zoom-55 {
  zoom: 0.55;
}

.zoom-50 {
  zoom: 0.5;
}

.dropdown-item.active .text-secondary {
  color: #fff !important;
  opacity: 0.6;
}

@media (max-width: 768px) {
  /* Hubspot */
  .widget-align-right {
      left: 0 !important;
  }
}
@media print {
  /* Hubspot */
  .widget-align-right {
    display: none !important;
    visibility: hidden !important;
  }
  .hs-drag-handle {
    display: none !important;
  }
}

