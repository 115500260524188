@media print {
  .noPrint {
    display: none;
  }

  body {
    background-color: white !important;
  }

  .container-xxl {
    box-shadow: none !important;
  }

  .printBody {
    position: absolute !important;
    top: 0px !important;
    left: 0px !important;
    height: auto !important;
    overflow: visible !important;
    width: 100% !important;
  }

  .modal-body,
  .modal-fullscreen {
    position: relative;
    overflow: visible !important;
  }

  .btn {
    display: none !important;
  }
}

.dropdownHeightLimit {
  height: auto;
  max-height: 200px;
  overflow-y: scroll;
}
