@import "styles/variables";

.form-control[type="file"] {
  position: absolute;
  display: none;
}

.form-file {
  height: var(--bs-form-file-height);
}

.focus-0 {
  outline: none !important;

  &:focus {
    box-shadow: none !important;
  }
}

.focus-bg-light {
  outline: none !important;
  &:hover {
    border-color: var(--bs-primary) !important;
  }
  &:focus {
    background-color: var(--bs-light);
    box-shadow: none !important;
  }
}

.form-control-actions {
  position: absolute;
  right: 0;
  top: 0;
  margin-right: 5px;
  line-height: 1;
  height: calc(1.5em + 0.75rem + 2px);
}

.form-control-actions-select {
  right: 70px;
  top: 9px;
  margin-right: 0;
  height: auto !important;
  background-color: $light;
  button {
    font-size: 12px;
  }
}

.form-control-actions-sm {
  height: calc(1.5em + 0.5rem + 2px);
}

.row .form-control-actions {
  right: calc(var(--bs-gutter-x) / 2);
}

.__react_component_tooltip {
  max-width: 400px;
  opacity: 1 !important;
  background: #000 !important;
  .form-control-actions {
    height: calc(1.5em + 0.75rem + 7px);
  }
}

.PhoneInput {
  .PhoneInputCountry {
    // background: $gray-200;
    border: 1px solid $input-border-color !important;
    border-right: none !important;
    border-radius: $border-radius 0 0 $border-radius;
    padding: 0 0 0 10px;
    margin-right: 0;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    .PhoneInputCountrySelectArrow {
      margin-left: 12px;
    }

    .PhoneInputCountry {
    }
    .PhoneInputCountrySelect {
    }
    .PhoneInputCountryIcon {
    }
  }
  .form-control {
    border-left: none;
    border-radius: 0 $border-radius $border-radius 0;
  }
  &.PhoneInput--focus {
    .PhoneInputCountry,
    .form-control {
      // background-color: #fff !important;
      // border-color: $blue !important;
    }
  }
}

[role="button"] {
  cursor: pointer;
}
