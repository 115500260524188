@import "styles/variables";

.react-calendar {
  width: $sidebar-width;
  margin: 0 auto;
  border: none;
  color: var(--bs-dark);
}

.react-calendar
  .react-calendar__month-view__days__day.highlight:not(.react-calendar__tile--active):not(.react-calendar__tile--now) {
  background: #ffc10738;
  color: #9c7500;
}

.react-calendar__tile.react-calendar__month-view__days__day {
  height: 33px;
  max-height: 33px;
}

.react-calendar__tile:active,
.react-calendar__tile:focus {
  outline: none !important;
}

.react-calendar__navigation {
  margin-bottom: 0em;
}

.react-calendar__tile.react-calendar__year-view__months__month {
  font-size: 0.8rem;
  padding: 1.3em 1em;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden !important;
}

.react-calendar__month-view__weekdays__weekday {
  font-size: 0.6rem;
  abbr[title] {
    text-decoration: none;
  }
}

.react-calendar__navigation {
  height: 30px;
}

.react-calendar__navigation button {
  min-width: 30px;
}

.react-calendar__navigation__label:first-letter {
  text-transform: capitalize;
}

.react-calendar__navigation__label__labelText {
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 0.8rem;
  color: $dark;
}

.react-calendar__month-view__days {
  font-size: 0.7rem;
}
