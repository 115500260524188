.callout {
  padding: 1.25rem;
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
  border: 1px solid #e9ecef;
  border-left-width: 0.25rem;
  border-radius: 0.25rem;

  p:last-child {
    margin-bottom: 0;
  }
}

.callout-warning {
  border-left-color: var(--bs-warning);
}

.callout-danger {
  border-left-color: var(--bs-danger);
}

.callout-success {
  border-left-color: var(--bs-success);
}

.callout-info {
  border-left-color: var(--bs-info);
}
