@import "./variables";

.btn-xs {
  padding: 2px;
}

.btn-outline-gray {
  &:hover {
    background: $gray-300;
  }
}

.btn-active-0:focus {
  box-shadow: none !important;
  border-bottom-color: $primary !important;
}

.btn-primary {
  color: #fff;
  &:hover {
    color: #fff;
  }
}

.btn-outline-primary,
.btn-outline-warning,
.btn-outline-danger,
.btn-outline-info,
.btn-outline-dark,
.btn-outline-secondary {
  &:hover {
    color: #fff !important;
  }
}

.btn-primary,
.btn-warning,
.btn-danger,
.btn-info,
.btn-dark,
.btn-secondary {
  &:active,
  &:focus {
    color: #fff !important;
  }
}

.shadow-0 {
  box-shadow: none !important;
}

.close.rbt-close {
  @include button-variant(transparent, transparent, $danger);
  @include button-size(
    $btn-padding-y-sm,
    $btn-padding-x-sm,
    $btn-font-size-sm,
    $btn-border-radius-sm
  );
}
