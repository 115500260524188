@import "./variables";

.bg-danger-light {
  background-color: #dc354521;
}

.bg-pink {
  background-color: $pink;
}

.bg-purple {
  background-color: $purple;
}

.bg-indigo {
  background-color: $indigo;
}

.bg-teal {
  background-color: $teal;
}

.bg-cyan {
  background-color: $cyan;
}

.bg-light-blue {
  background-color: #e9f4ff;
}

[class*="bg-light:odd"] {
  > div:nth-of-type(odd) {
    background-color: $light;
  }
}

[class*="bg-light:even"] {
  > div:nth-of-type(even) {
    background-color: $light;
  }
}

[class*="bg-dark:odd"] {
  > div:nth-of-type(odd) {
    background-color: $dark;
  }
}

[class*="bg-dark:even"] {
  > div:nth-of-type(even) {
    background-color: $dark;
  }
}

[class*="bg-success:odd"] {
  > div:nth-of-type(odd) {
    background-color: $success;
  }
}

[class*="bg-success:even"] {
  > div:nth-of-type(even) {
    background-color: $success;
  }
}

[class*="bg-danger:odd"] {
  > div:nth-of-type(odd) {
    background-color: $danger;
  }
}

[class*="bg-danger:even"] {
  > div:nth-of-type(even) {
    background-color: $danger;
  }
}

[class*="bg-warning:odd"] {
  > div:nth-of-type(odd) {
    background-color: $warning;
  }
}

[class*="bg-warning:even"] {
  > div:nth-of-type(even) {
    background-color: $warning;
  }
}

[class*="bg-info:odd"] {
  > div:nth-of-type(odd) {
    background-color: $info;
  }
}

[class*="bg-info:even"] {
  > div:nth-of-type(even) {
    background-color: $info;
  }
}

[class*="bg-pink:odd"] {
  > div:nth-of-type(odd) {
    background-color: $pink;
  }
}

[class*="bg-pink:even"] {
  > div:nth-of-type(even) {
    background-color: $pink;
  }
}

[class*="bg-indigo:odd"] {
  > div:nth-of-type(odd) {
    background-color: $indigo;
  }
}

[class*="bg-indigo:even"] {
  > div:nth-of-type(even) {
    background-color: $indigo;
  }
}

[class*="bg-purple:odd"] {
  > div:nth-of-type(odd) {
    background-color: $purple;
  }
}

[class*="bg-purple:even"] {
  > div:nth-of-type(even) {
    background-color: $purple;
  }
}

[class*="bg-cyan:odd"] {
  > div:nth-of-type(odd) {
    background-color: $cyan;
  }
}

[class*="bg-cyan:even"] {
  > div:nth-of-type(even) {
    background-color: $cyan;
  }
}

[class*="bg-teal:odd"] {
  > div:nth-of-type(odd) {
    background-color: $teal;
  }
}

[class*="bg-teal:even"] {
  > div:nth-of-type(even) {
    background-color: $teal;
  }
}
