@import "../../../styles/variables";

.form {
  font-size: 1rem !important;
  @media screen and (max-width: 768px) {
    max-width: 100%;
  }
  // min-width: 300px !important;
  z-index: 1050;
  position: relative;
  margin: 0;
  margin-top: -30px !important;
}

.reschedule {

}

.candidate {
  display: none;
}

.slot:hover .candidate {
  display: inline;
}

.slot:hover .hideme {
  display: none !important;
}

.slot:hover .time {
  color: #000;
  opacity: 1 !important;;
}

.header .col:nth-of-type(even) {
  background: #fff;
}

.header .col:nth-of-type(odd) {
  background: $gray-100;
}

.header .col {
  height: 57px;
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);
  &:not(:last-of-type) {
    // border-right: 1px solid rgba(0, 0, 0, 0.05);
  }
}

.header .col small {
  font-size: 12px;
  font-weight: 500;
  line-height: 1;
}

.header .col :global(a) {
  font-size: 20px;
  line-height: 1;
}

.days {
  height: calc((var(--vh, 1vh) * 100) - 66px - 57px);
}

.days .day:nth-of-type(even) {
  background: #fff;
}

.days .day:nth-of-type(odd) {
  background: $gray-100;
}

.days .day {
  // border-left: 1px solid rgba(0, 0, 0, 0.05);
}

.days .day .slot {
  height: 60px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  // border-left: 1px solid rgba(0, 0, 0, 0.05);
  // border-right: 1px solid rgba(0, 0, 0, 0.05);
  margin-left: -1px;
  // background: #fff;
  &.current {
    background: $gray-200 !important;
  }
}

.locked,
.past,
.past .slot,
.locked .slot {
  > button {
    opacity: 0.75;
  }
}

.days .day .slot:hover {
  background: lighten($blue-100, 6%);
}

.days .day .slot .events .event {
  cursor: pointer;

  .label {
    overflow: hidden;
    font-weight: 500;
  }
}
