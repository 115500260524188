.mce-content-body {
  width: 100%;
  min-height: 70px;
  padding: 10px;
  back
  p {
    margin-bottom: 0;
    line-height: 1.2;
  }
  &:focus-visible {
    outline: none !important;
  }
  &:before {
    color: #000;
    opacity: 1;
    font-weight: 300;
    font-family: var(--bs-body-font-family);
    top: 0;
    left: 0 !important;
    padding: 10px;
    line-height: 1;
    @media print {
      display: none;
    }
  }
  @media print {
    min-height: auto !important;
  }
  &.tiny {}
  > *:last-child {
    margin-bottom: 0;
  }
  &.readonly {
    min-height: 0;
  }
}
