@import "../../styles/variables";

.container {
}

.account-container {
  max-width: 320px;
}

.plus-container {
  max-width: 320px;
}
