@import "../../../../styles/variables";
@import "~bootstrap/scss/tables";

.reset {
  p {
    margin: 0 !important;
    // line-height: 1 !important;
    white-space: pre-line;
  }
  li {
    line-height: 1 !important;
  }
  *:last-child {
    margin-bottom: 0;
  }
}

.search {
  box-shadow: none !important;
  &:focus, &:active {
    border-color: transparent !important;
  }
  &:active, &:focus {
    background: var(--bs-light) !important;
  }
}
