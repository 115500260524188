.input {
  height: 40px;
  @media print {
    min-height: auto !important;
    height: auto !important;
    &[type="date"]::-webkit-inner-spin-button,
    &[type="date"]::-webkit-calendar-picker-indicator {
      display: none !important;
      -webkit-appearance: none !important;
    }
  }
}
