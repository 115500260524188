@import "../../../../styles/variables";
@import "~bootstrap/scss/tables";

.container {
  table {
    @extend .table;
    @extend .table-bordered;
  }
  tr,
  td,
  th,
  table {
    height: auto !important;
  }
}

.date {
  font-family: $font-family-monospace;
}

.editor {
  :global(.tox) {
    border: none !important;
    border-bottom: 1px solid $border-color !important;
    box-shadow: none !important;
  }
}
