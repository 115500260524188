@import "./variables";

.text-pink,
.link-pink {
  color: $pink;
}

.text-purple,
.link-purple {
  color: $purple;
}

.text-indigo,
.link-indigo {
  color: $indigo;
}

.text-teal,
.link-teal {
  color: $teal;
}

.text-cyan,
.link-cyan {
  color: $cyan;
}

.font-small-caps {
  font-variant: small-caps;
}

.text-first-up::first-letter,
.text-first-up:first-letter {
  text-transform: uppercase;
}
.text-xs {
  font-size: 13px;
}
.text-pre {
  white-space: pre;
}
.text-nowrap {
  white-space: nowrap;
}
