@import "../../../styles/variables";

.container {
  :global(input),
  :global(textarea),
  :global(.btn) {
    box-shadow: none !important;
  }
  @media print {
    :global(table) {
      td,
      th {
        padding: 4px !important;
        margin: 0 !important;
        font-size: 12px;
        * {
          font-size: 12px;
          padding: 0 !important;
          margin: 0 !important;
          &:not(input[type="checkbox"]):not(input[type="radio"]) {
            @media print {
              min-height: auto !important;
              height: auto !important;
              *[class*="multiValue"] {
                padding: 2px !important;
                line-height: 1;
              }
              *[class*="multiValue"] + *[class*="multiValue"] {
                margin: 0 0 0 5px !important;
              }
            }
          }
        }
      }
      th {
        font-weight: bold !important;
        padding: 8px !important;
        * {
          font-weight: bold !important;
        }
      }
    }
  }
}
.title {
  font-weight: 500;
}

.input {
  font-weight: 500;
  color: #000 !important;
  &::-webkit-input-placeholder {
    color: var(--bs-danger) !important;
    font-weight: 400;
  }
  &:-moz-placeholder {
    color: var(--bs-danger) !important;
    font-weight: 400;
  }
  &::-moz-placeholder {
    color: var(--bs-danger) !important;
    font-weight: 400;
  }
  &:-ms-input-placeholder {
    color: var(--bs-danger) !important;
    font-weight: 400;
  }
  @media print {
    padding-left: 4px !important;
  }
}
