@import "../../../styles/variables";

.container {
  min-width: $sidebar-width;
  width: $sidebar-width;
  position: relative;
  top: 0;
  left: 0;
  transition: left 0.25s cubic-bezier(0.82, 0.085, 0.395, 0.895),
    width 0.5s cubic-bezier(0.82, 0.085, 0.395, 0.895),
    min-width 0.5s cubic-bezier(0.82, 0.085, 0.395, 0.895);

  &.hide {
    left: -$sidebar-width;
    width: 0;
    min-width: 0;
  }
  :global([aria-expanded="true"][data-toggle="collapse"] .bi:nth-of-type(1)) {
    display: none;
  }
  :global([aria-expanded="false"][data-toggle="collapse"] .bi:nth-of-type(2)) {
    display: none;
  }

  .wrapper {
    overflow: auto;
  }
}
