.container {
   max-width: 100%;
  :global(.form-floating input:not(.is-invalid)),
  :global(.form-floating select:not(.is-invalid)),
  :global(.form-floating textarea:not(.is-invalid)) {
    background-color: #fafafa !important;
  }
  :global(.is-invalid) {
    background-color: #ffc10738 !important;
    border-color: #ffc107 !important;
    box-shadow: 0 0 0 1px #ffc107 !important;
  }
  :global(.is-invalid) + label {
    color: #9c7500 !important;
  }
  @media screen and (max-height: 700px) {
    zoom: 0.85;
  }
  input[type="date"] + input[type="time"] {
    // max-width: 100px;
  }
}
