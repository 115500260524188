@import "./variables";

.status-proposed {
  color: $orange;
}
.status-pending {
  color: $orange;
}

.status-booked {
  color: $green;
}
.status-arrived {
  color: $green;
}
.status-active {
  color: $green;
}

.status-fulfilled {
  color: $blue;
}

.status-cancelled {
  color: $red;
}
.status-no-show {
  color: $red;
}
.status-error {
  color: $red;
}

.status-checked-in {
  color: $yellow;
}
.status-waitlist {
  color: $pink;
}
