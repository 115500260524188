dl:last-of-type {
  margin-bottom: 0;
}

dd {
  margin: 0;
}

dd + dt {
  margin-top: 0.5rem;
}
